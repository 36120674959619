import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
	state: {
		savedCrewMembers: [
		],
	},
	getters: {
		getSavedCrewMembers: state => {
			return state.savedCrewMembers;
		},
	},
	mutations: {
        initialLoadFromLocal(state) {
			var crew = JSON.parse(localStorage.getItem("savedCrewMembers"));
			state.savedCrewMembers = crew;
        },
		saveCrewMembers(state, crewMembers) {
			state.savedCrewMembers = crewMembers;
			localStorage.setItem('savedCrewMembers', JSON.stringify(state.savedCrewMembers))
		},
		clearAllCrewMembers(state) {
			state.savedCrewMembers = []
			localStorage.setItem('savedCrewMembers', state.savedCrewMembers)
		}
	}
})
