import { render, staticRenderFns } from "./CrewForm.vue?vue&type=template&id=52d5412a&scoped=true&"
import script from "./CrewForm.vue?vue&type=script&lang=js&"
export * from "./CrewForm.vue?vue&type=script&lang=js&"
import style0 from "./CrewForm.vue?vue&type=style&index=0&id=52d5412a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52d5412a",
  null
  
)

export default component.exports