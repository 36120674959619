<template>
  <div class="crew-declaration-wrapper">

    <div v-show="responseMessage" v-bind:class="{ failed: !succeeded }" class="response-message">
      {{ responseMessage }}

      <ul v-show="confirmationList">
        <li v-for="crew in confirmationList" v-bind:key="crew.name">
          {{ crew.name }}
        </li>
      </ul>

      {{ otherInstructions }}
    </div>

    <div class="date-input">
      <label>Sailing Date:</label>
      <input v-model="sailingDate" type="date"/>
    </div>


    <div class="boat-name-input">
      <label>Boat Name:</label>
      <input v-model="boatName" type="text"/>
    </div>

    <div class="crew-list container">

    <div class="crew-list-header row align-items-start">
      <div class="col head col-small">On Board</div>
      <!--<div class="col head">AS Number</div>-->
      <div class="col head">Name</div>
    </div>

    <div
        v-for="(crew_member, index) in crew"
        :key="index"
        class="crew-item row align-items-start"
    >
      <div class="col col-small">
      <input v-model="crew_member.on_board" type="checkbox"/>
      </div>

      <div class="col">
        <input v-model="crew_member.crew_name" placeholder="Name" size="20" type="text"/>
      </div>

      <div class="extra-crew-details container" v-show="crew_member.on_board">
        <div class="extra-crew-details-header row">
          <div class="col head">AS Number</div>
          <div class="col head">Contact Ph</div>
          <div class="col head">Emergency Ph</div>
        </div>

        <div class="extra-crew-details-details gutter-2 row">
          <div class="col">
            <input v-model="crew_member.as_number" placeholder="AS Number" size="8" type="text"/>
          </div>

          <div class="col">
            <input v-model="crew_member.contact_phone" placeholder="Contact Phone" size="8" type="text"/>
          </div>

          <div class="col">
            <input v-model="crew_member.emergency_phone" placeholder="Emergency Phone" size="8" type="text"/>
          </div>
        </div>
      </div>
    </div>

    <div class="new-crew-member row align-items-start">
      <div class="col col-small">
      <input v-model="crew_member_new.on_board" type="checkbox"/>
      </div>

      <div class="col">
        <input v-model="crew_member_new.crew_name" placeholder="Name" size="20" type="text"/>
      </div>

      <div class="extra-crew-details container" v-show="crew_member_new.on_board">
        <div class="extra-crew-details-header row">
          <div class="col head">AS Number</div>
          <div class="col head">Contact Ph</div>
          <div class="col head">Emergency Ph</div>
        </div>

        <div class="extra-crew-details-details gutter-2 row">
          <div class="col">
          <input v-model="crew_member_new.as_number" placeholder="AS Number" size="8" type="text"/>
          </div>

          <div class="col">
            <input v-model="crew_member_new.contact_phone" placeholder="Contact Phone" size="8" type="text"/>
          </div>

          <div class="col">
            <input v-model="crew_member_new.emergency_phone" placeholder="Emergency Phone" size="8" type="text"/>
          </div>
        </div>
      </div>
    </div>

      <p><a href="#" v-on:click="addCrewMember" class="add-button">Add Another Crew Member</a></p>
    </div>

    <div class="submit-wrapper">
      <a
          href="#"
          v-on:click="submit"
          class="save-button button button-action btn btn-sm btn-primary"
      >Submit Crew Declaration</a>
    </div>
    <div class="helpText">
      <p>Don't know your AS number?</p>
      <p><a href="https://www.sailing.org.au/member-finder/" target="_blank">Find it here</a>.</p>
    </div>
    <div class="helpText">
      <p>All crew must be current members of KYC or other Australian Sailing associated club for insurance purposes.</p>
      <p><a href="https://www.ketteringyachtclub.org.au/membership/" target="_blank">Register for a full or  temporary (3 races max) KYC membership</a>.</p>
    </div>

    <div class="sponsor">
      <p>Web App Provided By <a href="https://www.eightyoptions.com.au" target="_blank">Eighty Options</a></p>
    </div>

    <div class="privacy">
      <p><a href="/privacy">Privacy Policy</a></p>
    </div>
  </div>
</template>

<script>
    import Axios from 'axios';
    import moment from 'moment';

    export default {
        components: {
        },
        data() {
            return {
                loading: 0,
                api: {},
                sailingDate: moment().format('YYYY-MM-DD'),
                boatName: "",
                crew: [],
                crew_member_new: {
                    on_board: 0,
                    as_number: "",
                    crew_name: "",
                    contact_phone: "",
                    emergency_phone: "",
                },
                local_store: [],
                responseMessage: "",
                succeeded: true,
                confirmationList: [],
                otherInstructions: "",
            };
        },
        mounted() {
            // this.local_store = this.$store.getters.getSavedCrewMembers;
            var savedCrewMembers = this.$store.getters.getSavedCrewMembers;

            if (savedCrewMembers != undefined) {
                // Don't pre-populate sailing date.
                //this.sailingDate = savedCrewMembers.sailingDate;

                this.boatName = savedCrewMembers.boatName;
                this.crew = savedCrewMembers.crew;

                for (let i = 0; i < this.crew.length; i++) {
                    this.crew[i]['on_board'] = 0;
                }
            }
        },
        methods: {
            addCrewMember() {
                var crewMember = {
                    on_board: this.crew_member_new.on_board,
                    as_number: this.crew_member_new.as_number,
                    crew_name: this.crew_member_new.crew_name,
                    contact_phone: this.crew_member_new.contact_phone,
                    emergency_phone: this.crew_member_new.emergency_phone,
                };
                this.crew.push(crewMember);

                // Then black out form fields for new item.
                this.crew_member_new = {
                    on_board: 0,
                    as_number: "",
                    crew_name: "",
                    contact_phone: "",
                    emergency_phone: "",
                };
            },
            clear() {
                this.local_store = [];
            },
            submit() {
                // Blank confirmation list out.
                this.confirmationList = [];
                this.otherInstructions = '';

                // If crew_member_new has a value, add that to this.crew first.
                if (this.crew_member_new['as_number'] != '' || this.crew_member_new['crew_name'] != '') {
                    this.addCrewMember();
                }

                // This should be a good time to clear out any empty rows.
                for (let i = 0; i < this.crew.length; i++) {
                    if (this.crew[i]['as_number'] == '' && this.crew[i]['crew_name'] == '') {
                        this.crew.splice(i,1);
                    }
                }

                var crewMembers = {
                    sailingDate: this.sailingDate,
                    boatName: this.boatName,
                    crew: this.crew,
                };


                if (crewMembers.sailingDate == '') {
                  this.setResponseMessage('Sailing date is required.', 'error');
                  return;
                }

                if (crewMembers.boatName == '') {
                    this.setResponseMessage('Please provide a boat name.', 'error');
                    return;
                }

                var someoneOnBoard = false;
                var onBoardDetailsIncluded = true;
                for (let i = 0; i < this.crew.length; i++) {
                  if (this.crew[i]['on_board'] == 1) {
                    someoneOnBoard = true;

                    if (this.crew[i]['as_number'] == undefined ||
                        this.crew[i]['as_number'] == '' ||
                        this.crew[i]['contact_phone'] == undefined ||
                        this.crew[i]['contact_phone'] == '' ||
                        this.crew[i]['emergency_phone'] == undefined ||
                        this.crew[i]['emergency_phone'] == '') {
                      onBoardDetailsIncluded = false;
                    }
                  }
                }
                if (!someoneOnBoard) {
                  this.setResponseMessage("There is nobody listed as on board. I don't think that's a good idea.", 'error');
                  return;
                }

                if (!onBoardDetailsIncluded) {
                    this.setResponseMessage("Please provide AS Number, contact phone and emergency phone details for all crew on board.", 'error');
                    return;
                }


                this.$store.commit("saveCrewMembers", crewMembers);

                // We only need the on board members to send to the server.
                // Flatten it here to, make it easier on the other end.
                var onBoardCrew = [];
                for (let i = 0; i < this.crew.length; i++) {
                    if (this.crew[i]['on_board'] == 1) {
                        var crewMember = {
                            "Sailing Date": this.sailingDate,
                            "Boat Name": this.boatName,
                            "AS Number": this.crew[i]['as_number'],
                            "Crew Name": this.crew[i]['crew_name'],
                            // Quote marks in front of phone numbers so leading zeros are kept in spreadsheet.
                            "Contact Phone": "'" + this.crew[i]['contact_phone'],
                            "Emergency Phone": "'" + this.crew[i]['emergency_phone'],
                        };

                        onBoardCrew.push(crewMember);
                    }
                }

                if (onBoardCrew.length > 40) {
                    this.setResponseMessage("Too many people on board. Even Solquest can't carry that many.", 'error');
                    return;
                }

                this.setResponseMessage('Processing...', 'success');

                this.uploadCrewList(onBoardCrew);

            },
            uploadCrewList(onBoardCrew) {

                // URL of AWS API Gateway.
                var axios = Axios.create({
                    baseURL: 'https://bwxxcjntcl.execute-api.ap-southeast-2.amazonaws.com/test',
                });
                var path = '/crew';

                axios.post(path, JSON.stringify(onBoardCrew), {
                })
                .then(response => {
                  // We're expecting a JSON string with result: success.
                  if (response.data.result != undefined && response.data.result == 'success') {
                    let msg = 'Crew declaration complete. ' + onBoardCrew.length + ' POB:';
                    for (let i = 0; i < onBoardCrew.length; i++) {
                        this.confirmationList.push({name: onBoardCrew[i]['Crew Name']})
                    }
                    this.otherInstructions = "If you need to make changes, submit the form again."

                    // Confirmation message will disappear after 2 minutes.
                    this.setResponseMessage(msg, 'success', 120000);

                    // Clear form for next submit by setting the date to today and blanking the on board status.
                    this.sailingDate = new Date().toISOString().slice(0,10);
                    for (let i = 0; i < this.crew.length; i++) {
                        this.crew[i]['on_board'] = 0;
                    }
                  }
                  else {
                      this.setResponseMessage("Sorry, something went wrong and your data wasn't accepted. The server responded with: " + JSON.stringify(response.data));
                  }
                })
                .catch(() => {
                    this.setResponseMessage("Couldn't submit the data. Maybe you're not online? Please try again later.", 'error');
                });


            },
            setResponseMessage(responseMessage, errorType, timeout) {
                // Set message colour.
                if (errorType == 'success') {
                    this.succeeded = true;
                }
                else {
                    this.succeeded = false;
                }
                this.responseMessage = responseMessage;

                if (timeout != undefined) {
                    setTimeout(() => {
                        this.hideResponseMessage()
                    }, timeout);
                }
            },
            hideResponseMessage() {
                this.responseMessage = '';
                // Also blank out confirmation list.
                this.confirmationList = [];
                this.otherInstructions = "";
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  label {
    margin-right: 1em;
  }

  .col-small {
    flex: 0 0 5em;
  }

  .crew-list-header {
    background-color: #808080;
    color: #ffffff;
  }

  .crew-list-header .head {
    align-self: center;
  }

  .crew-list.container {
    max-width: 568px;
  }

  .crew-list.container .row {
    padding-top: 0.2em;
    padding-bottom: 0.2em;
  }

  .response-message {
    max-width: 568px;
    padding: 1em;
    background-color: #cfe8ce;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    border-radius: 1em;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  .response-message.failed {
    background-color: #FF895E;
  }

  .extra-crew-details {
    font-size: 0.8em;
    background-color: #e3e3e3;
    margin: 0.5em;
  }

  .gutter-2.row {
    margin-right: -1px;
    margin-left: -1px;
  }
  .gutter-2 > .col, .gutter-2 > [class^="col-"], .gutter-2 > [class^=" col-"] {
    padding-right: 1px;
    padding-left: 1px;
  }

  .submit-wrapper {
    padding-bottom: 3em;
  }

  .sponsor, .privacy {
    font-size: 0.7em;
  }


</style>