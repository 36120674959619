import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import './registerServiceWorker'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { store } from './store/store'

Vue.use(Vuex, VueAxios, axios)

Vue.config.productionTip = false

store.commit('initialLoadFromLocal')

new Vue({
	render: h => h(App),
	store
}).$mount('#app')

require('@/assets/css/main.css')
// require('~/node_modules/boostrap/dist/css/bootstrap.css')

import 'bootstrap/dist/css/bootstrap.css'
